import { conf } from "@/setup/config";

function formatHeader(accessT: string){
  if(accessT){
    return {"X-auth-token": 'Bearer '+accessT}
  }
}
export function post(path: string, data: any, token: any){
    return fetch(`${conf().BACKEND_URL}${path}`, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         ...formatHeader(token)
       },
       body: JSON.stringify(data)
     }).then(response => response.json())
}

export function get(path: string, token: any){
  const headers = {
    'Content-Type': 'application/json',
    ...formatHeader(token)
  }
  return fetch(`${conf().BACKEND_URL}${path}`, {
      method: 'GET',
      headers: headers
    }).then(response => response.json())
}
export function extGet(url: string){
  const headers = {
    'Content-Type': 'application/json'
  }
  return fetch(`${url}`, {
      method: 'GET',
      headers: headers
    }).then(response => response.json())
}
 
  