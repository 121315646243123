import { get } from '@/backend/helpers/apiClient';
import create from 'zustand';

export const useStore = create((set) => ({
    userAuthData: null,
    accessToken: null,
  
    setUserAuth: (obj: any) => {
        const { user, access_token } = obj
        if(access_token){
            localStorage.setItem('accessToken', access_token);
            set({ accessToken: access_token })
        }
        if(user){
            set({ userAuthData: user })
        }
    },
    logout: () => {
        set({ userAuthData: null })
        set({ accessToken: null })
        localStorage.removeItem('accessToken');
        window.location.reload()
    },
    refetchUser: async () => {
        const accessT = localStorage.getItem("accessToken")
        console.log('START REFRESH USER', accessT)
        if(accessT){
            const data = await get('customers', accessT)
            const { user, access_token } = data
            if(access_token){
                localStorage.setItem('accessToken', access_token);
                set({ accessToken: access_token })
            }
            if(user){
                set({ userAuthData: user })
            }
        }
        console.log('END REFRESH USER')

    }
  }));
  