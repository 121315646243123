import create from 'zustand';
import { persist } from 'zustand/middleware'

export const useSettingStore: any = create(
    persist(
        (set: Function, get: Function) => ({
            accountPreferences: {},
            setEnableAutoPlay: (autoplay: boolean, customerId: string) => set({accountPreferences: {...get().accountPreferences, [customerId]: {...get().accountPreferences[customerId], autoplay: autoplay}} }),
            setStilling: (stylling: any, customerId: string) => {
                set({accountPreferences: {...get().accountPreferences, [customerId]: {...get().accountPreferences[customerId], stylling: stylling}} })
            },
        }),
        {
          name: 'MW::account', // name of the item in the storage (must be unique)
        },
      ),
);
  