import create from 'zustand';
import { persist } from 'zustand/middleware'

export const useProfileStore: any = create(
    persist(
        (set: Function, get: Function) => ({
            profile: null,
            setProfile: (profile: any) => set({profile}),
        }),
        {
          name: 'MW::profile', // name of the item in the storage (must be unique)
        },
      ),
);
  